<template>
  <v-card 
    :flat="!smUp"
    rounded
    :style="smUp ? 'padding: 15px; margin-left: -5%;':''">
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      :items-per-page="20"
    >
    <template 
      v-slot:item="props">
      <tr
        v-if="smUp"
        class="application_table_row">
        <td
          class="text-left">
          <p 
            v-html="props.item.sName"
            class="text_content"
          />
        </td>
        <td
          class="text-left">
          <p 
            v-html="props.item.sPhone"
            class="text_content"
          />
        </td>
        <td
          class="text-left">
          <p 
            v-html="props.item.sEmail"
            class="text_content"
          />
        </td>
        <td
          class="text-left">
          <v-btn
            fab
            small
            icon
            text
             @click="edit_operator(props.item)">
            <v-icon
              color="black">
              {{edit_icon}}
            </v-icon>
          </v-btn>
          <v-btn
            fab
            small
            icon
            text
            @click="delete_operator(props.item)">
            <v-icon
              color="red">
              {{delete_icon}}
            </v-icon>
          </v-btn>
        </td>
      </tr>
      <v-card
        v-else
        style="padding: 10%; margin-bottom: 20px; margin-top: 20px"
        hover
        shaped
        >
        <v-container>
          <v-layout
            row
            justify-center
            wrap>
            <v-flex
              class="attribute_flex"
              xs12
              sm6>
              <v-layout
                row
                justify-center>
                <v-layout
                  style="max-width: 60%"
                  row>
                  <p
                    class="attribute_name"
                    v-text="'Name: '"
                  />
                  <v-spacer></v-spacer>
                  <p
                    class="attribute_value"
                    v-text="props.item.sName"
                  />
                </v-layout>
              </v-layout>
            </v-flex>
            <v-layout
              column>
              <hr
                class="divider_table_mobile">
            </v-layout>
            <v-flex
              class="attribute_flex"
              xs12
              sm6>
              <v-layout
                row
                justify-center>
                <v-layout
                  style="max-width: 60%"
                  row>
                  <p
                    class="attribute_name"
                    v-text="'Phone: '"
                  />
                  <v-spacer></v-spacer>
                  <p
                    class="attribute_value"
                    v-text="props.item.sPhone"
                  />
                </v-layout>
              </v-layout>
            </v-flex>
            <v-layout
              column>
              <hr
                class="divider_table_mobile">
            </v-layout>
            <v-flex
              class="attribute_flex"
              xs12
              sm6>
              <v-layout
                row
                justify-center>
                <v-layout
                  style="max-width: 60%"
                  row>
                  <p
                    class="attribute_name"
                    v-text="'Email: '"
                  />
                  <v-spacer></v-spacer>
                  <p
                    class="attribute_value"
                    v-text="props.item.sEmail"
                  />
                </v-layout>
              </v-layout>
            </v-flex>
            <v-layout
              column>
              <hr
                class="divider_table_mobile">
            </v-layout>
            <v-flex
              xs12
              sm12>
              <v-layout
                row
                justify-center>
                <v-layout
                  style="max-width: 50%; margin: 10px"
                  row
                  justify-center>
                  <v-btn
                    dark
                    color="black"
                    fab
                    small
                    @click="edit_operator(props.item)">
                    <v-icon>
                      {{edit_icon}}
                    </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    color="red"
                    fab
                    small
                    @click="delete_operator(props.item)">
                    <v-icon>
                      {{delete_icon}}
                    </v-icon>
                  </v-btn>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </template>
    </v-data-table>    
    <operator-edit
      :bActive="b_edit_operator"
      :operator_to_edit="operator_to_edit"
      @close="b_edit_operator = false"
      @update="update()"
    />
    <operator-delete
      :bActive="b_delete_operator"
      :operator_id="operator_to_delete"
      @close="b_delete_operator = false"
      @update="update()"
    />
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      operator_to_edit: {},
      operator_to_delete: null,
      b_edit_operator: false,
      b_delete_operator: false,
      delete_icon: 'mdi-delete',
      edit_icon: 'mdi-pencil',
      headers: [
        { text: 'Nombre', value: 'sName' },
        { text: 'Teléfono', value: 'sPhone' },
        { text: 'Email', value: 'sMail' },
        { text: 'Acciones'}
      ]
    }),
    methods: {
      update () {
        this.$emit('update')
      },
      edit_operator (client) {
        this.operator_to_edit = client
        this.b_edit_operator = true
      },
      delete_operator (client) {
        this.operator_to_delete = client._id
        this.b_delete_operator = true
      }
    },
    components: {
      OperatorEdit: () => import('@/components/sections/Admin/Operators/OperatorEdit'),
      OperatorDelete: () => import('@/components/sections/Admin/Operators/OperatorDelete'),
    },
    props: {
      items: Array
    },
    computed: {
      smUp () {
        return this.$vuetify.breakpoint.smAndUp
      }
    }
  }
</script>
<style scoped>
  .attribute_flex {
    margin-top: 18px;
  }
  hr { 
    height: .5px;
    color: rgba(140, 140, 140, 0.112);
    background-color: rgba(114, 114, 114, 0.112);
    border-color: transparent;

  }
  .attribute_name {
    font-weight: bold;
    color: #000000;
    font-size: 12px;
    font-family: 'Open sans', sans-serif
  }
  .attribute_value {
    color: #000000;
    font-size: 12px;
    font-family: 'Open sans', sans-serif
  }
  .application_table_row {
    cursor: pointer;
  }
  .text_content {
    margin-top: 15px;
    color: #000000;
    font-size: 12px;
    font-family: 'Open sans', sans-serif
  }
</style>